import React, { useState } from 'react'
import { getCountryKey, getLanguageId } from 'helpers/country'
import { S3BucketJobseeker } from 'images'
import { ChangeLangAndRegion } from '../Common/ChangeLangAndRegion'

/* Style */
import styles from '../Header.module.scss'
import { languages } from 'helpers/country.base'
// import dynamic from 'next/dynamic'

// async function getServerCountryKey() {
//   return import('next/headers')
//     .then(result => {
//       const headers = result.headers() as any;
//       const path = headers.get('host') || headers.get(':authority');
//       let country = countryConfig.find((item) => path?.includes?.(item.url))
//       // 兼容处理demo.bossjob.com.tr 和 demo.bossjob.com
//       if (path?.indexOf('.tr') > -1) {
//         country = countryConfig.find((item) => item.key === 'tr')
//       }

//       if (country) {
//         return country.key
//       } else {
//         return ''
//       }
//     })
// }

// const Flag = dynamic(() => {
//   const maker = async () => {
//     let countryKey = ''
//     if (typeof window === 'undefined') {
//       countryKey = await getServerCountryKey()
//     } else {
//       countryKey = getCountryKey()
//     }
//     const countryFlagIcon =
//       countryKey === 'com'
//         ? `${S3BucketJobseeker}/flags/flag_inter.png`
//         : `${S3BucketJobseeker}/flags/${countryKey.toUpperCase()}.png`
//     return {
//       default: () => <span
//         style={{
//           display: 'flex', width: 20, height: 20, borderRadius: '50%',
//           backgroundPosition: 'center',
//           backgroundSize: 'cover',
//           backgroundImage: `url(${countryFlagIcon})`,
//           border: '1px solid #f6f6f6'
//         }}
//       />
//     }
//   }
//   return maker()
// })

const countryLanguage = ({ countryKey }) => {
  const languageId = getLanguageId()
  const [show, setShow] = useState(false)

  const languageSlug = languages.find((item) => item.id === languageId)?.slug
  const click = () => {
    setShow(true)
  }
  const countryFlagIcon =
    countryKey === 'com'
      ? `${S3BucketJobseeker}/flags/flag_inter.png`
      : `${S3BucketJobseeker}/flags/${countryKey.toUpperCase()}.png`
  return (
    <>
      <span
        onClick={click}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}
      >
        {/* <Flag /> */}
        <span
          style={{
            display: 'flex',
            width: 30,
            height: 20,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(${countryFlagIcon})`,
            border: '1px solid #f6f6f6'
          }}
        />
        <span className={styles.separator} style={{ padding: '0 8px' }}>
          |
        </span>
        <span className={styles.label}>{languageSlug}</span>
      </span>

      <ChangeLangAndRegion
        show={show}
        onAction={({ show }) => {
          setShow(show)
        }}
      />
    </>
  )
}

export default countryLanguage
