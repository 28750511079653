'use client'
import { useState, useCallback } from 'react'
import useSearchHistory from 'helpers/useSearchHistory'
import { fetchSearchSuggestionService } from 'store/services/jobs/fetchSearchSuggestion'

const transObject = (params) => {
  const result = {}
  params.forEach((e, index) => {
    result[`query_histories[${index}]`] = e
  })
  return result
}

export const useSuggest = () => {
  const [suggestionList, setSuggestionList] = useState([])
  const [searchHistories, addSearchHistory] = useSearchHistory()
  const [loading, setLoading] = useState(false)

  const handleSuggestionSearch = useCallback((val) => {
    const valueLength = val?.length ?? 0
    if (valueLength === 0) {
      setSuggestionList(searchHistories)
    } else {
      setLoading(true)
      const qs = transObject(searchHistories)
      fetchSearchSuggestionService({ size: 5, query: val, ...qs })
        .then((data) => setSuggestionList(data.data.data.items))
        .finally(() => setLoading(false))
    }
  }, [])

  return [suggestionList, handleSuggestionSearch, addSearchHistory, loading]
}
