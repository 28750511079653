'use client'
import React, { Suspense } from 'react'
// import Image from 'next/image'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
/* Style */
import styles from '../Header.module.scss'

/* components */
import Link from 'components/Link'
import Text from 'components/Text'
import MaterialButton from 'components/MaterialButton'

/* Images */
import linkToHunt from 'helpers/linkToHunt'

/* help */
import { addEventTag } from 'helpers/utilities'

import {
  efficiencySvg,
  accuracySvg,
  qualitySvg,
  angleSvg,
  chatSvg,
  recommendationsSvg,
  informationSvg
} from './svgFile'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
// import dynamic from 'next/dynamic'
import CountryLanguage from './countryLanguage'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
// const CountryLanguage = dynamic(() => import('./countryLanguage'), {
//   ssr: true,
//   loading: () => <div className={styles.skeleton}></div>
// })

interface IProps {
  lang: any
  langKey: string
  countryKey: string
}

const NavRight = (props: IProps) => {
  const { lang, langKey, countryKey } = props
  const {
    getStarted,
    hiring,
    efficiency,
    chatWithTalentOnline,
    accuracy,
    matchingPositionsAndTalents,
    highQuality,
    highQualityTalentRecommendations,
    afterRegistrationOrLogin,
    chatWithBossOnline,
    accurateJobRecommendations,
    getMoreJobInformation
  } = lang || {}
  const pathname = usePathname()
  // const [open, setOpen] = useState(false)

  const employerAdv = (
    <div className={styles.mainBox}>
      <span className={styles.angle}>{angleSvg}</span>
      <div className={styles.efficiency}>
        <Link title='Employer' to={linkToHunt('', langKey)} aTag className={styles.hoverBox}>
          <div className={styles.svg}>{efficiencySvg}</div>
          <h5>{efficiency}</h5>
          <p>{chatWithTalentOnline}</p>
        </Link>
      </div>
      <div className={styles.accuracy}>
        <Link title='Employer' to={linkToHunt('', langKey)} aTag className={styles.hoverBox}>
          <div className={styles.svg}>{accuracySvg}</div>
          <h5>{accuracy}</h5>
          <p>{matchingPositionsAndTalents}</p>
        </Link>
      </div>
      <div className={styles.quality}>
        <Link title='Employer' to={linkToHunt('', langKey)} aTag className={styles.hoverBox}>
          <div className={styles.svg}>{qualitySvg}</div>
          <h5>{highQuality}</h5>
          <p>{highQualityTalentRecommendations}</p>
        </Link>
      </div>
    </div>
  )
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: 'none'
    }
  }))

  const startedAdv = (
    <div className={styles.mainBox}>
      <span className={styles.angle}>{angleSvg}</span>
      <h5>{afterRegistrationOrLogin}</h5>
      <div className={styles.item}>
        <span>{chatSvg}</span>
        {chatWithBossOnline}
      </div>
      <div className={styles.item}>
        <span>{recommendationsSvg}</span>
        {accurateJobRecommendations}
      </div>
      <div className={styles.item}>
        <span>{informationSvg}</span>
        {getMoreJobInformation}
      </div>
    </div>
  )

  const handleRecordTag = (act) => {
    if (act === "hover") {
      addEventTag("hp_get_started_hover")
      return
    }

    if (act === "click") {
      addEventTag("hp_get_started_click")
      return
    }
  }

  return (
    <>
      <ul className={styles.headerLinksList}>
        <React.Fragment>
          <li
            className={classNames([
              styles.headerLink,
              styles.headerLinkRightItem,
              styles.headerLinkHiring
            ])}
            style={{ marginRight: '6px' }}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_employer'
            })}
          >
            <HtmlTooltip
              placement='bottom'
              title={
                <React.Fragment>
                  <div className={styles.employerAdv}>{employerAdv}</div>
                </React.Fragment>
              }
            >
              <div>
                <Link
                  to={linkToHunt('', langKey, countryKey)}
                  aTag
                  className={styles.hoverBox}
                  onClick={() => {
                    addEventTag('hp_employer_click')
                  }}
                  onMouseOver={() => {
                    addEventTag('hp_employer_hover')
                  }}
                >
                  <Text textStyle='base' className={styles.headerLinkText} style={{ fontSize: 16 }}>
                    {hiring}
                  </Text>
                </Link>
              </div>
            </HtmlTooltip>
          </li>
          <li
            className={classNames([styles.headerLink, styles.headerLinkRightItem])}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_country_language'
            })}
          >
            <div
              className={styles.countryItem + ' ' + styles.countryItemCountry}
              onClick={() => {
                // postNotification('SHOW_SIDE_GUIDE_MODAL')
              }}
            >
              <CountryLanguage countryKey={countryKey} />
            </div>
          </li>

          <li
            className={classNames([styles.headerLink, styles.headerLinkRightItem])}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_get_started'
            })}
          >
            <HtmlTooltip
              placement='bottom'
              title={
                <React.Fragment>
                  <div className={styles.startedAdv}>{startedAdv}</div>
                </React.Fragment>
              }
            >
              <div>
                {!pathname?.includes?.('/get-started') ? (
                  <MaterialButton
                    variant='outlined'
                    size='medium'
                    capitalize
                    className={styles.getStartedButton}
                    onClick={() => {
                      // showLoginModal()
                      postNotification('SHOW_LOGIN_MODAL')
                      sessionStorage.removeItem('chatJobId')
                      handleRecordTag('click')
                    }}
                    onMouseOver={() => {
                      handleRecordTag('hover')
                    }}
                  >
                    <span>{getStarted}</span>
                  </MaterialButton>
                ) : (
                  <MaterialButton
                    variant='outlined'
                    size='medium'
                    capitalize
                    className={styles.getStartedButton}
                    onClick={() => {
                      handleRecordTag('click')
                    }}
                    onMouseOver={() => {
                      handleRecordTag('hover')
                    }}
                  >
                    <span>{getStarted} </span>
                  </MaterialButton>
                )}
              </div>
            </HtmlTooltip>
          </li>
        </React.Fragment>
      </ul>
    </>
  )
}

export default NavRight
