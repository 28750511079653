/* eslint-disable no-unused-vars */
'use client'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styles from '../../index.module.scss'
import { lensProp, set } from 'ramda'
import { CircularProgress } from 'app/components/MUIs'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import MobileCard from '../../components/jobCard/index.mobile'
import { reportTrackerJobCounts } from 'components/ReportTracker/JobCountTracker'
import { jobCountSourceTracker } from 'components/ReportTracker/util'
import { fetchFindJobPageData } from '../../service'
import { transformParams } from '../../../interpreters/v2Encoder'
import { getLang } from 'helpers/country'

const MobileTable = (props: any) => {
  const { jobs = [], page, totalPages, searchValues, dictionary } = props
  const config = useSharedData('CONFIG')
  const { search = dictionary?.search } = useSharedData('DICTIONARY') ?? {}
  const [loading, setLoading] = useState(false)
  const [jobList, setJobList] = useState(jobs)
  const [latestPage, setLatestPage] = useState(page)

  useEffect(() => {
    setJobList(() => [...jobs])
    setLatestPage(1)
  }, [jobs])

  const noMore = useMemo(() => {
    return latestPage === +totalPages
  }, [latestPage, totalPages])

  const shouldLoadMore = useCallback(() => {
    if (noMore || loading) {
      return
    }
    setLoading(true)
    // @ts-ignore
    const newSearchValues = set(lensProp('page'))(['' + (latestPage + 1)])(searchValues)
    const lang = getLang()
    const payload = transformParams(newSearchValues, lang)
    fetchFindJobPageData({ query: '', ...payload })
      .then((response) => {
        const { jobs, page } = response
        setJobList(jobList.concat(jobs ?? []))
        setLatestPage(page)
        const jobsIds = (jobs || []).map((item) => item?.id).join(',')
        reportTrackerJobCounts({ source: jobCountSourceTracker.jobsHiring, job_ids: jobsIds })
      })
      .finally(() => setLoading(false))
  }, [noMore, loading, searchValues, latestPage, config?.industry_lists])

  const NoMore = () => {
    return noMore ? (
      <div style={{ color: '#86909c' }}>{search?.noMore}</div>
    ) : (
      <button className={styles.viewMore} onClick={shouldLoadMore}>
        {search?.viewMore}
      </button>
    )
  }

  return (
    <div className={styles.mobileContainer}>
      {jobList.map((job) => {
        return (
          <div className={styles.jobContainer} key={job?.job_id}>
            <MobileCard {...job} dictionary={props.dictionary} hideCompanyTag={true} />
          </div>
        )
      })}

      <div className={styles.loadMore}>
        {!loading ? <NoMore /> : <CircularProgress color={'primary'} size={20} />}
      </div>
    </div>
  )
}

export default MobileTable
