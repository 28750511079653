'use client'
import React, { useState, useEffect, useTransition, useContext } from 'react'
import styles from '../../index.module.scss'
import Pagination from '../../components/Pagination'
import JobCard from '../../components/jobCard'
import MobileTable from './mobile'
import Empty from 'app/components/empty/empty'
import { CompanyCard } from '../../components/companyCard'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { submitFeedbackService } from 'store/services/alerts/submitFB'
import Toast from 'app/components/Toast'
import { isMobile } from 'react-device-detect'
import Loading from './loading'
import { TransitionContext } from '../../components/transitionProvider'
import { v2BuildParams } from '../../../interpreters/v2Encoder'

function SubmitFeedback({ searchValues, job_search_empty, config }) {
  const { highlight_text, text } = job_search_empty
  const langDic = useSharedData('DICTIONARY') || {}
  const [disable, setDisable] = useState(false)

  const getIdsFromAlertData = (objData: Record<string, any>) => {
    const result = {};
    Object.keys(objData).forEach(key => {
      const value = objData[key]
      if (value && key.includes('ids')) {
        key = key.replace('ids', 'id');
        result[key] = value
      }
    })

    return result
  }

  const handleSubmitFeedback = () => {
    const alertData = v2BuildParams(config, searchValues)
    const data = getIdsFromAlertData(alertData)

    submitFeedbackService({ ...data, source: isMobile ? "mobile_web" : "web" }).then((data) => {
      Toast.success(langDic?.home?.feedbackSuccessful || 'Successful! Thank you for your feedback.')
      setDisable(true)
    }).catch(err => {
      console.log(err)
      Toast.error("submit failed")
    })
  }

  return <div className={styles.EmptyResultDesc}>
    {text}
    <a
      data-disabled={disable}
      onClick={
        !disable ? handleSubmitFeedback : undefined
      }>
      {highlight_text}
    </a>
  </div>
}

const Main = (props: any) => {
  const { page, totalPages, isMobile, emptyLang, dictionary, company, jobs, job_search_empty, config } = props
  const companyFlag = Object.keys(company).length || 0
  const { loading } = useContext(TransitionContext)

  return (
    <>
      {!isMobile &&
        (() => {
          if (loading) {
            return <Loading />
          }
          if (jobs?.length) {
            return (
              <div className={styles.tableContainer}>
                {companyFlag > 0 && <CompanyCard company={company} />}
                {jobs.map((item) => {
                  return (
                    <div className={styles.jobContainer} key={item?.job_id}>
                      <JobCard {...item} originDictionary={props.originDictionary} dictionary={dictionary} />
                    </div>
                  )
                })}
                {totalPages > 1 ? <Pagination count={+totalPages} page={+page} /> : null}
              </div>
            )
          } else {
            return (
              <Empty
                lang={emptyLang.search}
                description={
                  <SubmitFeedback searchValues={props.searchValues} job_search_empty={job_search_empty} config={config} />
                }
              />
            )
          }
        })()
      }

      {isMobile && (
        <div>
          {companyFlag > 0 && <CompanyCard company={company} />}
          {jobs?.length ? (
            <MobileTable {...props} jobs={jobs} dictionary={dictionary} />
          ) : (
            <Empty
              lang={emptyLang.search}
              description={
                <SubmitFeedback searchValues={props.searchValues} job_search_empty={job_search_empty} config={config} />
              }
            />
          )}
        </div>
      )}
    </>
  )
}

export default Main

