'use client'
import React, { useContext } from 'react'
import { useSearchParams, usePathname } from 'next/navigation'
// import MaterialRoundedPagination from 'components/MaterialRoundedPagination'
import styles from '../../index.module.scss'
import { useRouter } from 'next/navigation'
import dynamic from 'next/dynamic'
import { TransitionContext } from '../transitionProvider'
const MaterialRoundedPagination = dynamic(() => import('components/MaterialRoundedPagination'), { ssr: false })
const Pagination = (props: any) => {
  const { count, page, pageQueryKey = 'page' } = props
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  const { transitionStart } = useContext(TransitionContext)
  return (
    <div className={styles.tablePagination}>
      <MaterialRoundedPagination
        totalPages={count}
        page={page}
        boundaryCount={1}
        onChange={(e, page) => {
          const newSearchParams = new URLSearchParams(searchParams.toString())
          newSearchParams.set(pageQueryKey, '' + page)
          transitionStart(() => {
            router.push(pathname + '?' + newSearchParams.toString(), { scroll: false })
          })
          scrollTo(0, 0)
        }}
      />
    </div>
  )
}
export default Pagination
