'use client'
import React, { useState, useCallback, useEffect, useMemo, useRef, useContext } from 'react'
import { flushSync } from 'react-dom'
import { flatMap } from 'lodash-es'
import JobSearchBar from '../../../../../../components/commons/location/search'
import styles from '../../index.module.scss'
import MaterialButton from 'components/MaterialButton'
import Single from 'app/components/commons/select/single'
import Multiple from 'app/components/commons/select/multiple'
import { v2Encode, zipLocation } from '../../../interpreters/encoder'
import { useSuggest } from './hooks'
import theme from 'app/components/commons/theme'
import { ThemeProvider } from '@mui/material/styles'

import JobFunction from 'app/components/commons/NewJobFunction'
import LocationMultiSelector from 'app/components/commons/NewLocationMulty'
import SalarySelect from 'app/components/commons/NewSalarySelect'
import JobSearchFilters from 'app/components/commons/NewJobSearchFilters'

import { toPairs } from 'ramda'
import { Button } from 'app/components/MUIs'
import { getCookie } from 'helpers/cookies'
import { useFirstRender } from 'helpers/useFirstRender'
import { filter } from 'ramda'
import { HistoryIcons } from 'images'
import Image from 'next/image'
import classNames from 'classnames'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { MoreFilterIcon } from 'images'
import { useRouter } from 'next/navigation'
import { convertSpecialCharacters } from 'helpers/utilities'
import { TransitionContext } from '../../components/transitionProvider'

const SearchArea = (props: any) => {
  const { searchValues, dictionary, initialConfig: config = {} } = props
  const {
    sort_filter = [],
    location_lists = [],
    main_function_filter = [],
    salary_type_lists = {},
    job_types,
    job_type_text,

    search_text,
    salary_text,
    more_filter_text,
    clear_filter_text,
    location_text,
    input_text,
    filter_reset_text,
    filter_apply_text,
    filter_header_text,
    xp_lvl_text,
    degree_text,
    industry_text,
    company_size_text,
    financing_stage_text,
    main_function_text
  } = config

  const translate = {
    filter_reset_text,
    filter_apply_text,
    filter_header_text,
    xp_lvl_text,
    degree_text,
    industry_text,
    company_size_text,
    financing_stage_text,
    job_type_text
  }

  const { search } = dictionary
  const sortOptions = sort_filter.map((item) => {
    return { label: item.value, value: item.id?.toString() }
  })

  const sessionid = getCookie('sessionid')
  const userCookie = getCookie('user')
  const [page, setPage] = useState(searchValues.page ?? '1')
  const router = useRouter()
  const locations = useMemo(
    () => flatMap(location_lists, (item) => item.locations),
    [location_lists]
  )
  const [location, setLocation] = useState(
    locations.filter((location) => searchValues?.location?.includes(location.seo_value))
  )

  const [isFixed, setIsfixed] = useState(false)
  useEffect(() => {
    const listener = () => {
      const scrollTop = document.documentElement.scrollTop
      setIsfixed(scrollTop > 58)
    }
    window.addEventListener('scroll', listener, true)
    return window.removeEventListener('scroll', listener)
  }, [])

  const [salaries, setSalaries] = useState({
    salary: searchValues?.salary ?? [],
    salaryType: searchValues?.salaryType ?? []
  })

  const [jobFunctionValue, jobFunctionChange] = useState({
    functionTitles: searchValues?.functionTitles ?? [],
    jobFunctions: searchValues?.jobFunctions ?? [],
    mainFunctions: searchValues?.mainFunctions ?? []
  })
  const [showMore, setShowMore] = useState(false)
  const [sort, setSort] = useState(searchValues?.sort?.[0] ?? '2')

  const [moreData, setMoreData] = useState(
    filter((a) => a !== null && a !== undefined && a != '')({
      workExperience: searchValues?.workExperience ?? null,
      qualification: searchValues?.qualification ?? null,
      verifiedCompany: searchValues?.verifiedCompany ?? null,
      companySizes: searchValues?.companySizes ?? null,
      financingStages: searchValues?.financingStages ?? null,
      industry: searchValues?.industry ?? null
    })
  )
  const [jobTypes, setJobtypes] = useState(searchValues?.jobType ?? [])

  const jobTypeList =
    job_types?.map?.((item) => ({
      value: item?.['seo_value'],
      label: item.value
    })) ?? []

  const [searchValue, setSearchValue] = useState(searchValues.query)
  const [queryFields, setQueryFields] = useState(searchValues.queryFields)
  const [suggestionList, handleSuggestionSearch, addSearchHistory, searchLoading] =
    useSuggest() as any[]
  const { transitionStart } = useContext(TransitionContext)

  const handleGetRegion = (locations = []) => {
    if (!locations.length) {
      return undefined
    }

    const regions = locations.map((item) => {
      const parent = location_lists.find((parent) => parent.value == item.region_display_name)
      return parent ? parent.seo_value : null
    })
    return [...new Set(regions)]
  }

  const filterParams = useMemo(() => {
    const keyWords = searchValue?.trim?.().replace(/\s+/g, ' ')

    return filter((a) => a !== null && a !== undefined && a != '')({
      query: convertSpecialCharacters(keyWords),
      queryFields,
      region: handleGetRegion(location),
      location: location?.map((a) => a['seo_value']),
      jobType: jobTypes,
      sort: sort,
      page: page,
      ...salaries,
      ...jobFunctionValue,
      ...moreData
    })
  }, [searchValue, salaries, jobTypes, moreData, location, sort, jobFunctionValue, queryFields])

  const result = useMemo(() => {
    return v2Encode(filterParams)
  }, [filterParams])

  const firstRender = useFirstRender()
  const reload = useCallback(async () => {
    if (firstRender) {
      return
    }
    const zippedParams = await zipLocation(result.params)
    const url = new URLSearchParams(toPairs(zippedParams)).toString()
    transitionStart(() => {
      router.push('/jobs-hiring/' + result.searchQuery + '?' + url)
    })
  }, [result, transitionStart])

  const reloadRef = useRef(reload)

  useEffect(() => {
    reloadRef.current = reload
  }, [reload])

  useEffect(() => {
    reload()
  }, [location, salaries, jobTypes, moreData, sort, jobFunctionValue, queryFields])

  const moreCount = useMemo(() => {
    return Object.values(moreData).reduce((a1, a2: any) => a1 + (a2?.length ?? 0), 0)
  }, [moreData])

  const styleleSelect = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
  const spanStyle = {
    paddingLeft: '10px',
    fontSize: '15px'
  }

  return (
    <div className={styles.searchWrap}>
      <ThemeProvider theme={theme}>
        <div
          className={classNames({
            [styles.searchForm]: true,
            [styles.isFixed]: isFixed
          })}
        >
          <div className={styles.searchAreaContent}>
            <div className={styles.searchArea}>
              <div className={styles.searchAreaLeft}>
                <LocationMultiSelector
                  className={styles.location}
                  locationList={location_lists}
                  value={location}
                  isMobile={false}
                  label={location_text}
                  lang={search}
                  top={64}
                  left={0}
                  position={'findJob'}
                  onChange={setLocation}
                  searchValueLocations={searchValues?.location || []}
                  sx={{
                    '> .MuiFormControl-root': {
                      borderRadius: '8px',
                      height: '60px',
                      marginTop: '4px',
                      overflow: 'hidden',
                      '> .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        height: '60px',
                        overflow: 'hidden',
                        marginTop: '4px'
                      }
                    }
                  }}
                />
                <div className={styles.searchAreaBox}>
                  <JobSearchBar
                    id='search'
                    label={input_text}
                    variant='outlined'
                    size='small'
                    className={styles.search}
                    value={searchValue}
                    maxLength={255}
                    isLoading={searchLoading}
                    searchFn={handleSuggestionSearch as any}
                    updateSearchValue={setSearchValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        flushSync(() => {
                          setSearchValue((e.target as HTMLInputElement).value)
                        })
                        addSearchHistory((e.target as HTMLInputElement).value)
                        setQueryFields('')
                        reloadRef.current()
                      }
                    }}
                    renderOption={(props, option) => {
                      const { type, is_history: isHistory, value, logo_url: logoUrl } = option || {}
                      return type === 'company' ? (
                        <li {...props} style={styleleSelect}>
                          <Image src={logoUrl} alt={value} width='22' height='22' />
                          <span style={spanStyle}>{value}</span>
                        </li>
                      ) : isHistory ? (
                        <li {...props} style={{ ...styleleSelect, color: '#136fd3' }}>
                          <AccessTimeIcon />
                          <span style={spanStyle}>{value}</span>
                        </li>
                      ) : (
                        <li {...props} style={styleleSelect}>
                          <Image src={HistoryIcons} alt='history icons' width='17' height='17' />
                          <span style={spanStyle}>{value || option}</span>
                        </li>
                      )
                    }}
                    options={suggestionList}
                    onSelect={(value: any) => {
                      const newValue = value?.value || value || ''
                      setQueryFields(value?.type || '')
                      flushSync(() => {
                        setSearchValue(newValue)
                      })
                      addSearchHistory(newValue)
                      reloadRef.current()
                    }}
                  />
                  <MaterialButton
                    id='addSearchHistory'
                    className={styles.searchButton}
                    variant='contained'
                    capitalize
                    onClick={() => {
                      flushSync(() => {
                        setSearchValue(searchValue)
                      })
                      addSearchHistory(searchValue)
                      reloadRef.current()
                      setQueryFields('')
                    }}
                  >
                    {search_text}
                  </MaterialButton>
                </div>
              </div>
            </div>
            {/* filter */}
            <div className={styles.filters}>
              <div className={styles.filtersLeft}>
                <Single
                  options={sortOptions}
                  value={sort}
                  onSelect={setSort}
                  className={styles.filterItems}
                  label='Sort by'
                />

                <JobFunction
                  id='jobFunction'
                  label={main_function_text}
                  value={jobFunctionValue}
                  className={[styles.filterItems, styles.jobFunction]}
                  onChange={jobFunctionChange}
                  jobFunctions={main_function_filter}
                />

                <SalarySelect
                  id='SalarySelect'
                  salaryTypeLists={salary_type_lists}
                  label={salary_text}
                  value={salaries}
                  className={classNames([styles.filterItems, styles.jobSalary])}
                  onChange={setSalaries}
                />

                <Multiple
                  label={job_type_text}
                  value={jobTypes}
                  options={jobTypeList}
                  className={styles.filterItems}
                  onSelect={setJobtypes}
                  defaultValue={jobTypes}
                />
                <Button
                  className={classNames([
                    styles.moreButton,
                    moreCount ? styles.moreButtonCount : ''
                  ])}
                  variant='outlined'
                  onClick={() => {
                    setShowMore(true)
                  }}
                  endIcon={<Image src={MoreFilterIcon} width={16} height={16} alt='filter' />}
                >
                  {more_filter_text} {moreCount ? `(${moreCount})` : ''}
                </Button>
              </div>
              <div className={styles.filtersRight}>
                <Button
                  className={styles.clearButton}
                  variant='text'
                  onClick={() => {
                    setLocation([])
                    setSearchValue('')
                    setSort('1')
                    jobFunctionChange({
                      jobFunctions: [],
                      mainFunctions: [],
                      functionTitles: []
                    })
                    setJobtypes([])
                    setSalaries({} as any)
                    setMoreData({} as any)
                    setPage('1')
                    setQueryFields('')
                  }}
                >
                  {clear_filter_text}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>

      {showMore && (
        <JobSearchFilters
          urlDefaultValues={moreData}
          isShowFilter={showMore}
          onResetFilter={() => {
            setMoreData({} as any)
          }}
          keyword={searchValues.query}
          config={config}
          handleShowFilter={() => {
            setShowMore(false)
          }}
          moreFilterReset={false}
          isShowingEmailAlert={sessionid && !userCookie?.is_email_verify}
          setClientDefaultValues={(data) => {
            setMoreData(data)
          }}
          translate={translate}
        />
      )}
    </div>
  )
}
export default SearchArea
