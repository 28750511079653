'use client'
import { Suspense } from 'react'
import CompleteProfileCard from 'app/components/CompleteProfileCard'

const SeekerProfilePage = (props: any) => {
  return (
    <Suspense>
      <CompleteProfileCard {...props} />
    </Suspense>
  )
}

export default SeekerProfilePage
